import {configureStore} from "@reduxjs/toolkit";

// Reducers
import userProfileReducer from "./slices/userProfileSlice";

export const store = configureStore({
    reducer: {
        userProfile: userProfileReducer,
    }
})
