import React from 'react';
import {useNavigate} from "react-router-dom";
import logo from "../../assets/images/chessnchaiwhitebg.png";

const HeaderNavigation = () => {
    const navigate = useNavigate();

    return (
        <div className="text-center bg-light">
            <div className="theme-border-top" />
            <img className="mt-4 mb-4" role="button" src={logo} alt="Chess n' Chai logo" width={120} height={120} onClick={() => navigate('/')} />
            <div className="theme-border-bottom" />
        </div>
    );
};

export default HeaderNavigation;
