import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="bg-black position-absolute w-100 pb-3" style={{bottom: -100}}>
                <div className="text-center text-white mt-3">
                    <span className="text-center text-white mt-3">Copyright © 2023 - Chess n' Chai</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
