import {setUserProfileSlice} from "../slices/userProfileSlice";

// HEADER NAVIGATION SLICES
export function setCurrentHeaderNavigation(currentHeaderNavigation){
    return function(dispatch, getState) {
        //dispatch(updateCurrentHeaderNavigationSlice(currentHeaderNavigation));
    }
}

// USER PROFILE SLICES
export function setUserProfile(userProfile) {
    return function(dispatch, getState) {
        dispatch(setUserProfileSlice(userProfile));
    }
}

export function setLogoutUserProfile(userProfile) {
    return function(dispatch, getState) {
        dispatch(setLogoutUserProfile(userProfile));
    }
}
