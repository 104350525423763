import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../constants/appConstants";
import {postRequest} from "../../actions/process";
import Loader from "../common/Loader";
import {isObjectEmpty} from "../../helpers/appHelpers";

const ViewMemberGames = (props) => {
    const [init, setInit] = useState(false);
    const [games, setGames] = useState([]);

    useEffect(() => {
        getGameHistoryByMemberId();
    }, []);

    function getGameHistoryByMemberId() {
        let url = API_ENDPOINT + "v1/Games/getGameHistoryByMemberId";
        let params = {
            member_id: props.selectedMember.id
        }

        postRequest(url, params).then(results => {
            setGames(results.data);
            setInit(true);
        }).catch(error => {});
    }

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex w-100">
                            <h2 className="col-9">Match History</h2>
                            <div className="col-3 m-auto text-end">
                                <button className="btn btn-warning" onClick={() => props.setModal(false)}>Close</button>
                            </div>
                        </div>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", minHeight: "500px"}}>
                        {init ?
                            !isObjectEmpty(games) ?
                                games.map((game, index) =>
                                    <div>

                                    </div>
                                )
                            :
                                <p className="lead">No games were found</p>
                        :
                            <Loader />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewMemberGames;
