import React, {useEffect, useState} from 'react';

import '../../styles/leaderboard.css';

import Loader from "../common/Loader";
import ViewMemberGames from "../modals/ViewMemberGames";
import {API_ENDPOINT} from "../../constants/appConstants";
import {postRequest} from "../../actions/process";
import {getNumberSuffix, isObjectEmpty} from "../../helpers/appHelpers";
import {LEADERBOARD_GAMES_NAV} from "../../dataStructures/leaderboardDataStructures";
import {DateTime} from "luxon";

const Leaderboard = () => {
    const [gamesInit, setGamesInit] = useState(false);
    const [leaderboardInit, setLeaderboardInit] = useState(false);
    const [openViewMemberGamesModal, setOpenViewMemberGamesModal] = useState(false);

    const [games, setGames] = useState([]);
    const [membersData, setMembersData] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});

    const [currentTab, setCurrentTab] = useState(LEADERBOARD_GAMES_NAV[0]);

    useEffect(() => {
        getLeaderboardData();
        getGames();
    }, []);

    function getLeaderboardData() {
        let url = API_ENDPOINT + "v1/Games/getLeaderboardData";
        let params = {}

        postRequest(url, params).then(results => {
            setMembersData(results.data);
            setLeaderboardInit(true)
        }).catch(error => {})
    }

    function getGames() {
        let url = API_ENDPOINT + "v1/Games/getGames";
        let params = {}

        postRequest(url, params).then(results => {
            setGames(results.data);
            setGamesInit(true)
        }).catch(error => {})
    }

    function viewMemberGames(member) {
        setSelectedMember(member)
        setOpenViewMemberGamesModal(true);
    }

    function handleMenu(nav) {
        setCurrentTab(nav);
    }

    return (
        <div>
            <div className="d-flex justify-content-center mt-3">
                {LEADERBOARD_GAMES_NAV.map((nav, index) =>
                    <div className={`w-45 text-center border border-dark p-1 ${nav.constant === currentTab.constant ? 'bg-dark text-white' : ''}`} role="button" onClick={() => handleMenu(nav)}>
                        {nav.label}
                    </div>
                )}
            </div>

            {openViewMemberGamesModal &&
                <ViewMemberGames
                    selectedMember={selectedMember}
                    setModal={setOpenViewMemberGamesModal}
                />
            }

            {currentTab.constant === "leaderboard" ?
                leaderboardInit ?
                    <table className="leaderboard-table table table-striped w-90 m-auto mt-4">
                        <thead className="border border-dark border-start-0 border-end-0">
                            <tr>
                                <th style={{width: "2%"}}>#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Points</th>
                                <th scope="col">Wins</th>
                                <th scope="col">Losses</th>
                                <th scope="col">Draws</th>
                                <th scope="col" />
                            </tr>
                        </thead>

                        <tbody className="border border-0">
                            {membersData.map((member, index) =>
                                <tr>
                                    <td className={`bg-dark fw-bold ${member.statistics.rank === 1 ? 'gold' : ''}${member.statistics.rank === 2 ? 'silver' : ''}${member.statistics.rank === 3 ? 'bronze' : ''}`}>{`${member.statistics.rank}${getNumberSuffix(member.statistics.rank)}`}</td>
                                    <td>{member.first_name} {member.last_name}</td>
                                    <td>{member.statistics.points}</td>
                                    <td>{member.statistics.wins}</td>
                                    <td>{member.statistics.losses}</td>
                                    <td>{member.statistics.draws}</td>
                                    <td role="button" className="link-primary" onClick={() => viewMemberGames(member)}>View Games</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <Loader/>
            :
                gamesInit ?
                    <div className="w-90 m-auto mt-4">
                        {!isObjectEmpty(games) ?
                            games.map((game, index) =>
                                <div className={`bg-light p-3 border border-1 mb-3`}>
                                    <div className="d-flex">
                                        <div className="col-4">
                                            {game.game_won_by != 0
                                                ? <p className="lead fs-6 text-success fw-semibold text-decoration-underline">Win</p>
                                                : <p className="lead fs-6 text-dark fw-semibold text-decoration-underline">Draw</p>
                                            }
                                            <p className={`fs-4 fw-semibold lead ${game.game_won_by != 0 ? 'text-success' : 'text-dark'}`}>
                                                {game.game_won_by != 0
                                                    ? `${game.winner.first_name} ${game.winner.last_name}`
                                                    : `${game.player_white.first_name} ${game.player_white.last_name}`
                                                }
                                            </p>
                                        </div>
                                        <div className="col-4 text-center">
                                            <div className="fw-bold m-auto fs-4">VS</div>
                                            <div className="mt-2">{DateTime.fromSQL(game.created).toRelative()}</div>
                                            <div className="mt-2">{DateTime.fromSQL(game.created, {zone: "Australia/Sydney"}).toFormat("dd MMM y")}</div>
                                        </div>
                                        <div className="col-4 text-end">
                                            {game.game_won_by != 0
                                                ? <p className="lead fs-6 text-danger fw-semibold text-decoration-underline">Loss</p>
                                                : <p className="lead fs-6 text-dark fw-semibold text-decoration-underline">Draw</p>
                                            }
                                            <p className={`fs-4 fw-semibold lead ${game.game_won_by != 0 ? 'text-danger' : 'text-dark'}`}>
                                                {game.game_won_by != 0
                                                    ? `${game.loser.first_name} ${game.loser.last_name}`
                                                    : `${game.player_black.first_name} ${game.player_black.last_name}`
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        :
                            <p className="text-center">No games have been recorded yet</p>
                        }
                    </div>
                :
                    <Loader/>
                }

        </div>
    );
};

export default Leaderboard;
