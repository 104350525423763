import React, {useEffect, useState} from 'react';

import '../../styles/members.css';

import {API_ENDPOINT} from "../../constants/appConstants";
import {postRequest} from "../../actions/process";
import Loader from "../common/Loader";
import Footer from "../Footer";

const Members = () => {
    const [init, setInit] = useState(false);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        getMembers();
    }, []);

    function getMembers() {
        let url = API_ENDPOINT + "v1/Members/getMembers";
        let params = {};

        postRequest(url, params).then(results => {
            setMembers(results.data);
            setInit(true);
        })
        .catch(error => {});
    }

    return (
        <div>
            {init ?
                <table className="user-table table table-striped w-90 m-auto mt-4">
                    <thead className="border border-dark border-start-0 border-end-0">
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                    </tr>
                    </thead>
                    <tbody className="border border-0">
                        {members.map((member, index) =>
                            <tr key={index}>
                                <td>{member.first_name}</td>
                                <td>{member.last_name}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            :
                <Loader/>
            }
        </div>
    );
};

export default Members;
