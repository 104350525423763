import $ from 'jquery';
import * as appConstants from '../constants/appConstants';

export function postRequest(url, params) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: url,
            headers: {
                'app-key': appConstants.APP_KEY
            },
            data: params,
            dataType: "json",
            success: function (data) {
                resolve(data)
            },
            error: function (error) {
                reject(error)
            }
        });
    })
}


