export const LEADERBOARD_GAMES_NAV = [
    {
        constant: "leaderboard",
        label: "View Leaderboard"
    },
    {
        constant: "view_games",
        label: "View Games"
    }
];
