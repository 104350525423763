import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";

import './styles/theme.css';
import './styles/custom.css';

import Home from "./components/Home";
import Login from "./components/login/Login";
import Admin from "./components/admin/Admin";
import Events from "./components/events/Events";
import Members from "./components/members/Members";
import Register from "./components/register/Register";
import Leaderboard from "./components/leaderboard/Leaderboard";
import HeaderNavigation from "./components/navigations/HeaderNavigation";

import {isObjectEmpty} from "./helpers/appHelpers";
import Footer from "./components/Footer";

function App() {
    const userProfile = useSelector(state => state.userProfile);

    return (
        <BrowserRouter>
            <HeaderNavigation />

            <Routes>
                {!isObjectEmpty(userProfile) &&
                    <React.Fragment>
                        <Route path="/admin" element={<Admin />} />
                        <Route path="*" element={<Home />} />
                    </React.Fragment>
                }
                <Route path="*" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/events" element={<Events />} />
                <Route path="/members" element={<Members />} />
                <Route path="/register" element={<Register />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
            </Routes>

            <Footer />
        </BrowserRouter>
    );
}

export default App;
