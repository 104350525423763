import React from 'react';
import {Link} from "react-router-dom";

import '../styles/home.css';

import {MAIN_HOME_NAVIGATION} from "../dataStructures/mainNavigationStructures";
import Footer from "./Footer";

const Home = () => {
    return (
        <div className="w-100">
            <div className="w-80 m-auto">
                <div className="row">
                    {MAIN_HOME_NAVIGATION.map((nav, index) =>
                        <Link to={nav.link} className={`${nav.className} menu-images nav-card col-xl-6 col-12 text-decoration-none bg-white min-vh-50 d-flex justify-content-center align-items-center`}>
                            <h3 className="text-uppercase fs-2 text-center bg-dark text-white px-4 py-2">{nav.label}</h3>
                        </Link>
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;
