import React, {useState} from 'react';

import '../../styles/admin.css';

import {ADMIN_SIDE_NAVIGATION} from "../../dataStructures/adminNavigationStructures";

const Admin = () => {
    const [currentMenu, setCurrentMenu] = useState(ADMIN_SIDE_NAVIGATION[0]);

    function handleMenu(nav) {
        if(nav.sub_nav) {
            setCurrentMenu(nav.sub_nav[0]);
        }else{
            setCurrentMenu(nav);
        }
    }

    return (
        <div className="d-flex w-90 m-auto">
            <div className="col-2">
                <ul className="list-group p-4">
                    {ADMIN_SIDE_NAVIGATION.map((nav, index) =>
                        <React.Fragment>
                            <li role="button" className={`dashboard-nav ${currentMenu.constant === nav.constant || currentMenu.parent_constant === nav.constant ? 'active' : ''}`} onClick={() => handleMenu(nav)}>{nav.label}</li>

                            {(currentMenu.constant === nav.constant || currentMenu.parent_constant === nav.constant) && nav.sub_nav ?
                                nav.sub_nav.map((sub_nav, sub_nav_index) =>
                                    <div className={`dashboard-sub-nav`}>
                                        <li role="button" className={`${currentMenu.constant === sub_nav.constant ? 'active' : ''}`} onClick={() => handleMenu(sub_nav)}>{sub_nav.label}</li>
                                    </div>
                                )
                                : null}
                        </React.Fragment>
                    )}
                </ul>
            </div>

            <div className="col-10">
                <div className="p-4 px-5">
                    <h3>{currentMenu.label}</h3>
                    <hr />
                    <currentMenu.component />
                </div>
            </div>
        </div>
    );
};

export default Admin;
