import React from 'react';

const TextInput = (props) => {
    return (
        <React.Fragment>
            <div className={`${props.labelClassName} lead form-label fs-5`}>{props.label}</div>
            <input
                ref={props.ref}
                id={props.id || props.name}
                type={props.type || "text"}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
                disabled={props.disabled}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                onChange={props.onChange}
                onClick={props.onClick}
                onMouseDown={props.onMouseDown}
                onMouseLeave={props.onMouseLeave}
                onMouseOut={props.onMouseOut}
                onKeyDown={props.onKeyDown}
                autoFocus={props.autoFocus}
                className={`${props.className}`}
                style={{
                    ...props.style,
                    border: `${props.error ? '1px solid red' : ''}`
                }}
            />
            <div className="lead text-danger fs-6 mt-1 mb-2">{props.error}</div>
        </React.Fragment>
    );
};

export default TextInput;
