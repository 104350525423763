import React from 'react';

const Loader = () => {
    return (
        <div className="d-flex justify-content-center mt-5">
            <div className="spinner-border" role="status" style={{width: "4rem", height: "4rem"}} />
        </div>
    );
};

export default Loader;
