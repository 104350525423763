import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../constants/appConstants";
import {postRequest} from "../../actions/process";
import Loader from "../common/Loader";
import {isObjectEmpty} from "../../helpers/appHelpers";

const PendingMembers = () => {
    const [init, setInit] = useState(false);
    const [pendingMembers, setPendingMembers] = useState([]);

    useEffect(() => {
        getPendingMembers();
    }, []);

    function getPendingMembers() {
        let url = API_ENDPOINT + "v1/Members/getPendingMembers";
        let params = {};

        postRequest(url, params).then(results => {
            setPendingMembers(results.data);
            setInit(true);
        }).catch(error => {});
    }

    function verifyMember(_member) {
        let url = API_ENDPOINT + "v1/Members/verifyMember";
        let params = {
            member_id: _member.id
        };

        postRequest(url, params).then(results => {
            getPendingMembers();
        }).catch(error => {});
    }

    return (
        <div>
            {init ?
                !isObjectEmpty(pendingMembers) ?
                    pendingMembers.map((pendingMember, index) =>
                        <div className="d-flex p-3 border border-1 rounded">
                            <div className="col-9 m-auto">
                                {pendingMember.first_name} {pendingMember.last_name} - <span>{pendingMember.student_id}</span>
                            </div>

                            <div className="col-3 m-auto">
                                <button className="btn btn-warning w-40" onClick={() => verifyMember(pendingMember)}>Verify</button>
                            </div>
                        </div>
                    )
                :
                    <p>No pending members were found</p>
            :
                <Loader />
            }
        </div>
    );
};

export default PendingMembers;
