import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import TextInput from "../../helpers/inputHelpers/TextInput";

import {postRequest} from "../../actions/process";
import {isEmpty, isObjectEmpty} from "../../helpers/appHelpers";
import {API_ENDPOINT} from "../../constants/appConstants";
import {setUserProfile} from "../../helpers/actionHelpers";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [loginDetails, setLoginDetails] = useState({
        username: "",
        password: ""
    });

    function handleLoginDetails(event) {
        setLoginDetails({...loginDetails, [event.target.name]: event.target.value});
    }

    function validateForm(event) {
        event.preventDefault();
        let _errors = {};

        if(isEmpty(loginDetails.username)){
            _errors['username'] = "Username field is empty";
        }
        if(isEmpty(loginDetails.password)){
            _errors['password'] = "Password field is empty";
        }

        setErrors(_errors);
        if(isObjectEmpty(_errors)) {
            authenticateUser();
        }
    }

    function authenticateUser() {
        let url = API_ENDPOINT + "v1/Authentication/authenticateUser";
        let params = {
            username: loginDetails.username,
            password: loginDetails.password
        };

        postRequest(url, params).then(results => {
            if(!results.data.authenticated) {
                let _errors = {};
                _errors['authentication'] = "Authentication failed";

                setErrors(_errors);
            }else{
                dispatch(setUserProfile({
                    username: loginDetails.username
                }));
                navigate('/admin');
            }
        }).catch(error => {});
    }

    return (
        <div>
            <div className="w-50 m-auto mt-5">
                <form autoComplete="on">
                    {errors.authentication &&
                        <div className="text-danger text-center fst-italic fs-6 mb-2">
                            <small>{errors.authentication}</small>
                        </div>
                    }
                    <TextInput
                        name="username"
                        placeholder="Username"
                        value={loginDetails.username}
                        autoFocus={true}
                        autoComplete="username"
                        error={errors.username}
                        onChange={(e) => handleLoginDetails(e)}
                        className="form-control"
                    />

                    <TextInput
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={loginDetails.password}
                        autoComplete="current-password"
                        error={errors.password}
                        onChange={(e) => handleLoginDetails(e)}
                        className="form-control mt-3"
                    />

                    <div className="mt-3">
                        <button type="submit" className="btn theme-bg-green text-white fw-light w-25" onClick={(e) => validateForm(e)}>Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
