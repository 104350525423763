import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import TextInput from "../../helpers/inputHelpers/TextInput";
import {isEmpty} from "../../helpers/appHelpers";
import {postRequest} from "../../actions/process";
import {API_ENDPOINT} from "../../constants/appConstants";

const Register = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [memberDetails, setMemberDetails] = useState({
        first_name: "",
        last_name: "",
        student_id: "",
    })

    function handleChange(event) {
        setMemberDetails({...memberDetails, [event.target.name]: event.target.value});
    }

    function validateForm() {
        let _errors = {};

        if(isEmpty(memberDetails.first_name)) _errors['first_name'] = "Cannot be empty";
        if(isEmpty(memberDetails.last_name)) _errors['last_name'] = "Cannot be empty";
        if(isEmpty(memberDetails.student_id)) _errors['student_id'] = "Cannot be empty";

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            createMember();
        }
    }

    function createMember() {
        let url = API_ENDPOINT + "v1/Members/createMember";
        let params = {
            memberDetails: memberDetails
        }

        postRequest(url, params).then(results => {
            navigate('/');
        }).catch(error => {});
    }

    return (
        <div className="border border-1 rounded w-50 m-auto mt-4 p-3">
            <div className="mt-2 px-3">
                <TextInput
                    name="first_name"
                    label="First Name"
                    error={errors.first_name}
                    value={memberDetails.first_name}
                    onChange={(e) => handleChange(e)}
                    className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                    labelClassName="fw-normal"
                />
            </div>

            <div className="mt-4 px-3">
                <TextInput
                    name="last_name"
                    label="Last Name"
                    error={errors.last_name}
                    value={memberDetails.last_name}
                    onChange={(e) => handleChange(e)}
                    className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                    labelClassName="fw-normal"
                />
            </div>

            <div className="mt-4 mb-4 px-3">
                <TextInput
                    name="student_id"
                    label="Student ID"
                    error={errors.student_id}
                    value={memberDetails.student_id}
                    onChange={(e) => handleChange(e)}
                    className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                    labelClassName="fw-normal"
                />
            </div>

            <div className="p-3 text-center">
                <button className="btn btn-primary w-100 fs-5" onClick={() => validateForm()}>Register</button>
            </div>
        </div>
    );
};

export default Register;
