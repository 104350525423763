export const MAIN_HOME_NAVIGATION = [
    {
        constant: "leaderboard",
        label: "Leaderboard & Games",
        link: "/leaderboard",
        className: "leaderboard",
    },
    {
        constant: "events",
        label: "Events",
        link: "/events",
        className: "events"
    },
    {
        constant: "register",
        label: "Register",
        link: "/register",
        className: "register"
    },
    {
        constant: "members",
        label: "Members",
        link: "/members",
        className: "members"
    },
];
